import React, { useState, useEffect } from 'react';
import Appearance from 'styles/Appearance.js';

const Checkbox = ({ checked, color, enabled, onChange, style }) => {

    const [active, setActive] = useState(checked || false);

    const onBoxChange = evt => {

        // no additional logic is required if checkbox changes are not enabled
        if(enabled === false) {
            return;
        }

        // update local state with new value
        let next = !active;
        setActive(next);

        // notify subscribers of data change
        if(typeof(onChange) === 'function') {
            onChange(next, evt);
        }
    }

    useEffect(() => {
        setActive(checked || false);
    }, [checked]);

    return (
        <div
        className={enabled === false ? '' : 'text-button'}
        onClick={onBoxChange}
        style={{
            backgroundColor: Appearance.colors.background(),
            border: `1px solid ${Appearance.colors.softBorder()}`,
            borderRadius: 5,
            height: 20,
            opacity: enabled === false ? 0.25 : 1,
            overflow: 'hidden',
            padding: 4,
            width: 20,
            ...style
        }}>
            {active && (
                <div style={{
                    backgroundColor: color || Appearance.colors.primary(),
                    borderRadius: 10,
                    height: '100%',
                    overflow: 'hidden',
                    width: '100%'
                }}/>
            )}
        </div>
    )
}
export default Checkbox;