import React, { useEffect, useState } from 'react';

import Appearance from 'styles/Appearance.js';
import CountableLabel from 'views/CountableLabel.js';
import LottieView from 'views/Lottie.js';
import Panel from 'structure/Panel.js';
import Request from 'files/Request.js';

export const AFTSystemStatus = ({ index, options, utils }) => {

    const [date, setDate] = useState(null);
    const [loading, setLoading] = useState(true);
    const [stats, setStats] = useState([]);
    
    const getContent = () => {
        let isLoading = loading === true && stats.length === 0 ? true : false;
        if(isLoading === true) {
            return (
                <div 
                className={'col-12 p-3'}
                style={{
                    ...Appearance.styles.unstyledPanel(),
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    height: 100,
                    justifyContent: 'center',
                    marginTop: 12,
                    width: '100%'
                }}>
                    <LottieView
                    loop={true}
                    autoPlay={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 40,
                        width: 40
                    }}/>
                </div>
            )
        }

        return stats.map((entry, index) => {
            let healthy = entry.status_code < 300 ? true : false;
            return (
                <div 
                key={index}
                className={'col-12 col-lg-4 col-xl-3 p-2 pl-lg-1 pr-lg-1 pt-lg-2 pb-lg-0'}>
                    <div 
                    className={'p-3'}
                    style={{
                        ...Appearance.styles.unstyledPanel(),
                        alignItems: 'center',
                        backgroundColor: Appearance.colors.panelBackground(),
                        borderRadius: 12,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        minWidth: 0,
                        width: '100%'
                    }}>
                        <img 
                        src={healthy ? 'images/online-icon.png' : 'images/offline-icon.png'}
                        style={{
                            height: 60,
                            marginBottom: 8,
                            objectFit: 'contain',
                            width: 60
                        }} />
                        <span style={{
                            ...Appearance.textStyles.title(),
                            maxWidth: '100%'
                        }}>{entry.name}</span>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            color: healthy ? Appearance.colors.green : Appearance.colors.red,
                            maxWidth: '100%'
                        }}>{healthy ? 'Online' : 'Offline'}</span>
                    </div>
                </div>
            )
        });
    }

    const fetchStatus = async () => {
        try {
            setLoading(true);
            let { date, status } = await Request.get(utils, '/health/', {
                type: 'aft_system_status'
            });

            // prepare array of entries using status object
            let results = Object.keys(status).map(key => ({
                ...status[key],
                key: key
            }))
       
            // end loading and update stats state
            setLoading(false);
            setDate(date);
            setStats(results);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the aft system status data. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchStatus();
    }, []);

    return (
        <Panel
        index={index}
        name={'AFT Service Status'}
        panelID={'system_stats'}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            removePadding: true,
            shouldStyle: false,
            subTitle: (
                <>
                <span>{`The health status for Applied Fire Technologies mobile and desktop services was last updated `}</span>
                <CountableLabel date={date} />
                <span>{`. Please submit a support ticket if you are experiencing issues that are not being reported below.`}</span>
                </>
            )
        }}>
            <div className={'row p-0 m-0'}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const GlobalDataSystemStatus = ({ index, options, utils }) => {

    const [date, setDate] = useState(null);
    const [loading, setLoading] = useState(true);
    const [stats, setStats] = useState([]);
    
    const getContent = () => {
        let isLoading = loading === true && stats.length === 0 ? true : false;
        if(isLoading === true) {
            return (
                <div 
                className={'col-12 p-3'}
                style={{
                    ...Appearance.styles.unstyledPanel(),
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    height: 100,
                    justifyContent: 'center',
                    marginTop: 12,
                    width: '100%'
                }}>
                    <LottieView
                    loop={true}
                    autoPlay={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 40,
                        width: 40
                    }}/>
                </div>
            )
        }

        return stats.map((entry, index) => {
            let healthy = entry.status_code < 300 ? true : false;
            return (
                <div 
                key={index}
                className={'col-12 col-lg-4 col-xl-3 p-2 pl-lg-1 pr-lg-1 pt-lg-2 pb-lg-0'}>
                    <div 
                    className={'p-3'}
                    style={{
                        ...Appearance.styles.unstyledPanel(),
                        alignItems: 'center',
                        backgroundColor: Appearance.colors.panelBackground(),
                        borderRadius: 12,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        minWidth: 0,
                        width: '100%'
                    }}>
                        <img 
                        src={healthy ? 'images/online-icon.png' : 'images/offline-icon.png'}
                        style={{
                            height: 60,
                            marginBottom: 8,
                            objectFit: 'contain',
                            width: 60
                        }} />
                        <span style={{
                            ...Appearance.textStyles.title(),
                            maxWidth: '100%'
                        }}>{entry.name}</span>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            color: healthy ? Appearance.colors.green : Appearance.colors.red,
                            maxWidth: '100%'
                        }}>{healthy ? 'Online' : 'Offline'}</span>
                    </div>
                </div>
            )
        });
    }

    const fetchStatus = async () => {
        try {
            setLoading(true);
            let { date, status } = await Request.get(utils, '/health/', {
                type: 'global_data_system_status'
            });

            // prepare array of entries using status object
            let results = Object.keys(status).map(key => ({
                ...status[key],
                key: key
            }))
       
            // end loading and update stats state
            setLoading(false);
            setDate(date);
            setStats(results);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the global data system status data. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchStatus();
    }, []);

    return (
        <Panel
        index={index}
        name={'Global Data Service Status'}
        panelID={'system_stats'}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            removePadding: true,
            shouldStyle: false,
            subTitle: (
                <>
                <span>{`The health status for Global Data mobile and desktop services was last updated `}</span>
                <CountableLabel date={date} />
                <span>{`. Please submit a support ticket if you are experiencing issues that are not being reported below.`}</span>
                </>
            )
        }}>
            <div className={'row p-0 m-0'}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const OmnishieldSystemStatus = ({ index, options, utils }) => {

    const [date, setDate] = useState(null);
    const [loading, setLoading] = useState(true);
    const [stats, setStats] = useState([]);
    
    const getContent = () => {
        let isLoading = loading === true && stats.length === 0 ? true : false;
        if(isLoading === true) {
            return (
                <div 
                className={'col-12 p-3'}
                style={{
                    ...Appearance.styles.unstyledPanel(),
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    height: 100,
                    justifyContent: 'center',
                    marginTop: 12,
                    width: '100%'
                }}>
                    <LottieView
                    loop={true}
                    autoPlay={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 40,
                        width: 40
                    }}/>
                </div>
            )
        }

        return stats.map((entry, index) => {
            let healthy = entry.status_code < 300 ? true : false;
            return (
                <div 
                key={index}
                className={'col-12 col-lg-4 col-xl-3 p-2 pl-lg-1 pr-lg-1 pt-lg-2 pb-lg-0'}>
                    <div 
                    className={'p-3'}
                    style={{
                        ...Appearance.styles.unstyledPanel(),
                        alignItems: 'center',
                        backgroundColor: Appearance.colors.panelBackground(),
                        borderRadius: 12,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        minWidth: 0,
                        width: '100%'
                    }}>
                        <img 
                        src={healthy ? 'images/online-icon.png' : 'images/offline-icon.png'}
                        style={{
                            height: 60,
                            marginBottom: 8,
                            objectFit: 'contain',
                            width: 60
                        }} />
                        <span style={{
                            ...Appearance.textStyles.title(),
                            maxWidth: '100%'
                        }}>{entry.name}</span>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            color: healthy ? Appearance.colors.green : Appearance.colors.red,
                            maxWidth: '100%'
                        }}>{healthy ? 'Online' : 'Offline'}</span>
                    </div>
                </div>
            )
        });
    }

    const fetchStatus = async () => {
        try {
            setLoading(true);
            let { date, status } = await Request.get(utils, '/health/', {
                type: 'omnishield_system_status'
            });

            // prepare array of entries using status object
            let results = Object.keys(status).map(key => ({
                ...status[key],
                key: key
            }))
       
            // end loading and update stats state
            setLoading(false);
            setDate(date);
            setStats(results);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the omnishield system status data. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchStatus();
    }, []);

    return (
        <Panel
        index={index}
        name={'Omnishield Service Status'}
        panelID={'system_stats'}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            removePadding: true,
            shouldStyle: false,
            subTitle: (
                <>
                <span>{`The health status for Omnishield mobile and desktop services was last updated `}</span>
                <CountableLabel date={date} />
                <span>{`. Please check your comm link if you are experiencing issues that are not being reported below.`}</span>
                </>
            )
        }}>
            <div className={'row p-0 m-0'}>
                {getContent()}
            </div>
        </Panel>
    )
}