import React, { useRef, useState, useEffect } from 'react';

import { animated, useSpring } from '@react-spring/web';
import browserInfo from 'browser-info';
import moment from 'moment-timezone';
import update from 'immutability-helper';

import API from 'files/api.js';
import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import Checkbox from 'views/Checkbox.js';
import LottieView from 'views/Lottie.js';
import Request from 'files/Request.js';
import TextField from 'views/TextField.js';
import User from 'classes/User.js';
import { VelocityComponent } from 'velocity-react';

export const runLogin = (utils, props) => {

    return new Promise(async (resolve, reject) => {
        try {

            // verify the username and password have been provided
            let { auto_login, password, refresh_token, username } = props || {};
            if(!refresh_token && (!username || !password)) {
                throw new Error('Please enter your username and password to continue');
            }

            // prepare device name and operating system
            let { name, os } = browserInfo();
            
            // send request to server
            let { user } = await Request.aft.post(utils, '/users/', {
                browser: name,
                origin: 'homesafealerts',
                password: password,
                platform: os,
                refresh_token: refresh_token,
                type: 'login',
                username: username
            });

            // prevent non-eligible accounts from moving forward
            if(user.level > User.levels.get().safety_advisor) {
                throw new Error('It looks like your account is not able to use these services. Please speak with your dealer for more information');
            }

            // update cookies with access token for cross-platform logins
            let preferences = utils.graci.preferences.get();
            preferences.auto_login = typeof(auto_login) === 'boolean' ? auto_login : preferences.auto_login;
            preferences.last_login = moment().unix();
            preferences.refresh_token = user.refresh_token;
            utils.graci.preferences.set(preferences);

            // resolve default dealership object and user object
            resolve({ user: User.create(user) });

        } catch(e) {
            reject(e);
        }
    });
}

const Login = ({ onLogin, utils }) => {

    const credentials = useRef({}); 

    const [autoLogin, setAutoLogin] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loginVisible, setLoginVisible] = useState(false);
    const [password, setPassword] = useState(null);
    const [username, setUsername] = useState(null);

    const [loginComponents, setLoginComponents] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 12 },
        maxHeight: 0,
        opacity: 0,
        transform: 'scale(0)'
    }))

    const [login, setLogin] = useState({
        opacity: 0,
        scale: 0.75
    });

    const onButtonClick = () => {

        // run login process is login is visible
        if(loginVisible) {
            onRunLogin();
            return;
        }

        // toggle visibility for components and set keydown listener
        setLoginVisible(true);
        document.addEventListener('keydown', onKeyDown);

        // animate login components into view
        setLoginComponents({
            opacity: 1,
            maxHeight: 250,
            transform: 'scale(1)'
        });
    }

    const onLoginClick = async () => {
        try {

            // set loading flag and start root loader
            setLoading(true);
            await utils.loader.show();

            // submit login request to login processor
            let result = await runLogin(utils, {
                ...credentials.current,
                auto_login: autoLogin
            });

            // animate login out of view and remove keydown listener
            document.removeEventListener('keydown', onKeyDown);
            setLogin(login => {
                return update(login, {
                    opacity: {
                        $set: 0
                    },
                    scale: {
                        $set: 0.75
                    }
                });
            });

            // send account details back to root view
            setLoading(false);
            setTimeout(onLogin.bind(this, result), 500);

        } catch(e) {
            setLoading(false);
            utils.loader.hide();
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue logging into your account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onKeyDown = evt => {

        // prevent default keypress action and trigger login process if keypres is 'enter'
        if(evt.keyCode === 13) {
            evt.preventDefault();
            onRunLogin();
        }
    }

    const onRunLogin = () => {

        // set visible flag to false and remove keydown listener
        setLoginVisible(false);
        document.removeEventListener('keydown', onKeyDown);

        // animate login components out of view
        setLoginComponents({
            opacity: 0,
            maxHeight: 0,
            transform: 'scale(0)'
        });

        // trigger login process
        onLoginClick();
    }

    const onResetPasswordClick = () => {
        window.open(`${API.aft.server}/reset-password/index.html`);
    }

    const getContent = () => {
        return (
            <>
            <animated.div style={{
                paddingBottom: loginVisible ? 15 : 0,
                paddingTop: 20,
                width: '100%',
                ...loginComponents
            }}>
                <div style={{
                    ...Appearance.styles.panel(),
                    padding: 12
                }}>
                    <TextField
                    autoCapitalize={'none'}
                    fieldStyle={{
                        color: Appearance.colors.text(),
                        fontSize: 12,
                        fontWeight: '500',
                    }}
                    icon={'user'}
                    onChange={setUsername}
                    placeholder={'Username'}
                    useDelay={false}
                    value={username}/>

                    <TextField
                    appendContent={(
                        <img
                        className={'image-button'}
                        onClick={onResetPasswordClick}
                        src={'images/help-button-grey.png'}
                        style={{
                            borderRadius: 10,
                            height: 20,
                            marginLeft: 8,
                            objectFit: 'contain',
                            width: 20
                        }}/>
                    )}
                    containerStyle={{
                        marginTop: 8,
                        paddingRight: 6,
                        width: '100%'
                    }} 
                    fieldStyle={{
                        color: Appearance.colors.text(),
                        fontSize: 12,
                        fontWeight: 500
                    }}
                    icon={'lock'}
                    isSecure={true}
                    onChange={setPassword}
                    placeholder={'Password'}
                    useDelay={false}
                    value={password}/>

                    <div style={{
                        alignItems: 'center',
                        borderTop: `1px solid ${Appearance.colors.divider()}`,
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 12,
                        paddingTop: 12,
                        textAlign: 'left'
                    }}>
                        <Checkbox
                        checked={autoLogin}
                        color={Appearance.colors.primary()}
                        onChange={setAutoLogin} />
                        <span 
                        className={'cursor-pointer'}
                        onClick={() => setAutoLogin(val => !val)}
                        style={{
                            ...Appearance.textStyles.subTitle(),
                            color: Appearance.colors.text(),
                            marginLeft: 8
                        }}>{'Keep me signed in'}</span>
                    </div>
                </div>
            </animated.div>
            <Button
            color={'primary'}
            label={loginVisible ? 'Done' : 'Login'}
            loading={loading}
            onClick={onButtonClick}
            transition={{
                icon: 'key-icon-white.png'
            }}
            type={'transition'}/>
            </>
        )
    }

    useEffect(() => {
        credentials.current = { password, username };
    }, [password, username]);

    useEffect(() => {
        if(loginVisible === true) {
            document.addEventListener('keydown', onKeyDown);
        }
        if(loginVisible === false) {
            document.removeEventListener('keydown', onKeyDown);
        }
        return () => {
            document.removeEventListener('keydown', onKeyDown);
        }
    }, [loginVisible]);

    useEffect(() => {
        setLogin(login => {
            return update(login, {
                opacity: {
                    $set: 1
                },
                scale: {
                    $set: 1
                }
            });
        });
    }, []);

    return (
        <VelocityComponent
        easing={[250, 20]}
        duration={750}
        animation={{
            opacity: login.opacity,
            scale: login.scale
        }}>
            <div style={{
                padding: 12,
                maxWidth: window.innerWidth > 400 ? 400 : window.innerWidth,
                width: '100%'
            }}>
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 30,
                    textAlign: 'center',
                    width: '100%'
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={require('files/lottie/logo-desktop.json')}
                    style={{
                        height: 250,
                        width: 250
                    }}/>
                    <span style={{
                        color: Appearance.colors.text(),
                        fontSize: 22,
                        fontWeight: 800,
                        lineHeight: 1.5
                    }}>{'Home Safe Alerts'}</span>
                    <span style={{
                        color: Appearance.colors.subText(),
                        fontSize: 12,
                        fontWeight: 600,
                        lineHeight: 1.1
                    }}>{'Powered by Global Health and Safety'}</span>
                    {getContent()}
                </div>
            </div>
        </VelocityComponent>
    )
}

export default Login;
