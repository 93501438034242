
import React, { useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';

const AnimatedElement = ({ animateIn, animateOut, children, config, visible }) => {

    const [animations, setAnimations] = useSpring(() => ({
        config: config || { mass: 1, tension: 180, friction: 12 },
        ...animateOut
    }));
    const [visiblePrivate, setVisiblePrivate] = useState(false);

    const onSetAnimations = () => {

        // set internal visibility flag to true if applicable
        if(visible === true) {
            setVisiblePrivate(true);
        }

        // update animation values with visible based timeout
        setAnimations({
            to: async next => {

                // run animation
                await next(visible ? animateIn : animateOut);

                // set internal visibility flag to false after animation has completed if applicable
                if(visible === false) {
                    setVisiblePrivate(false);
                }
            }
        });
    }

    useEffect(() => {
        onSetAnimations();
    }, [visible]);

    return visiblePrivate === true && (
        <animated.div style={{
            ...animations,
            position: 'relative'
        }}>
            {children}
        </animated.div>
    )
}

export default AnimatedElement;