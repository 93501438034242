import React, { useEffect, useRef, useState } from 'react';

import Layer from 'structure/Layer.js';
import Utils from 'files/Utils.js';

export const SupportContantForm = ({ index, options, utils }) => {

    const layerID = 'support_contact_form';

    return (
        <Layer
        id={layerID}
        index={index}
        utils={utils}
        options={{
            ...options,
            removePadding: true,
            sizing: 'medium'
        }}>
            <iframe 
            src={'https://aftplatform.com/support/?id=11'} 
            style={{
                borderWidth: 0,
                height: Utils.isMobile() ? (window.innerHeight - 24) : (window.innerHeight / 1.5),
                width: '100%'
            }} />
        </Layer>
    )
}