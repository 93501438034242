import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import update from 'immutability-helper';

import API from 'files/api.js';
import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper.js';
import AnimatedElement from 'views/AnimatedElement.js';
import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import Layer, { LayerItem } from 'structure/Layer.js';
import LottieView from 'views/Lottie.js';
import MultipleListField from 'views/MultipleListField.js';
import Request from 'files/Request.js';
import TextField from 'views/TextField.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';

export const FlipChartDeviceModels = ({ date, style, utils }) => {

    const padding = Utils.isMobile() ? 24 : '1vw';

    const getContent = () => {

        // prepare text values for panel
        let values = [{
            text: 'Certain applications, particularly those that demand high processing power or advanced graphical capabilities, may require a newer iPad model to function optimally. This is often due to enhanced hardware features, such as faster processors, improved graphics engines, and increased memory, which newer iPads provide.'
        },{
            text: `While we recommend using a newer iPad model for the best experience with our apps, we understand that everyone has different needs and preferences. The list we've provided includes our top recommendations for iPads to ensure that your presentation runs smoothly. These models are specifically chosen to provide the best experience, but feel free to explore other options if you have a model that you would like to try out. We recommend using a modal that still receives software updates from Apple if you are using a model that is not on our list.`
        },{
            text: `If you already have an iPad, you can identify your model by checking the back of the device for the model number, which begins with an "A" followed by four digits (e.g., A1234). You can also navigate to "Settings," then tap "General" and select "About." Here, you'll find the model name and number listed alongside other details.`
        },{
            style: {
                color: Appearance.colors.red,
                fontWeight: 800
            },
            text: `We recommend using an iPad model running iOS 13 or newer. Models that no longer receive software updates from Apple should be avoided.`,
        }];

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
            }}>
                {values.map((value, index) => {
                    return (
                        <ResourceText 
                        key={index}
                        lastItem={index === values.length - 1}
                        {...value} />
                    )
                })}
                {getModels()}
            </div>
        )
    }

    const getModels = () => {

        // prepare list of supported ipad models
        let models = [{
            image: 'images/ipad-pro.png',
            title: 'iPad Pro',
            url: 'https://support.apple.com/en-us/111841'
        },{
            image: 'images/ipad-air-3rd-gen.png',
            title: 'iPad Air, 3rd generation or newer',
            url: 'https://support.apple.com/en-us/111939'
        },{
            image: 'images/ipad-6th-gen.png',
            title: 'iPad, 6th generation or newer',
            url: 'https://support.apple.com/en-us/111957'
        }];

        return models.map((model, index) => {
            if(Utils.isMobile() === true) {
                return (
                    <div 
                    key={index}
                    style={{
                        alignItems: 'center',
                        border: `1px solid ${Appearance.colors.divider()}`,
                        borderRadius: 10,
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 24,
                        padding: padding,
                        textAlign: 'center',
                        width: '100%'
                    }}>
                        <img
                        src={model.image}
                        style={{
                            height: 125,
                            marginBottom: padding,
                            objectFit: 'contain',
                            width: 125
                        }} />
                        <span style={{
                            ...Appearance.textStyles.title(),
                            whiteSpace: 'normal'
                        }}>{model.title}</span>
                        <span 
                        className={'text-button'}
                        onClick={window.open.bind(this, model.url)} 
                        style={{
                            ...Appearance.textStyles.subTitle(),
                            textDecoration: 'underline',
                            whiteSpace: 'normal'
                        }}>{model.url}</span>
                    </div>
                )
            }
            return (
                <div 
                key={index}
                style={{
                    alignItems: 'center',
                    border: `1px solid ${Appearance.colors.divider()}`,
                    borderRadius: 10,
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '2vw',
                    padding: padding,
                    width: '100%'
                }}>
                    <img
                    src={model.image}
                    style={{
                        height: 125,
                        marginRight: padding,
                        objectFit: 'contain',
                        width: 125
                    }} />
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1
                    }}>
                        <span style={{
                            ...Appearance.textStyles.heavyHeader(),
                        }}>{model.title}</span>
                        <span 
                        className={'text-button'}
                        onClick={window.open.bind(this, model.url)} 
                        style={{
                            ...Appearance.textStyles.title(),
                            color: Appearance.colors.subText(),
                            textDecoration: 'underline'
                        }}>{model.url}</span>
                    </div>
                </div>
            )
        });
    }

    return (
        <ResourceContainer
        date={date}
        id={'flipchart.supported_device_models'}
        style={style}
        title={'FlipChart Supported iPad Models'}
        utils={utils}>
            {getContent()}
        </ResourceContainer>
    )
}

export const GraciSupportContent = ({ assets, date, id, plan, platform, style, title, utils, values = [] }) => {

    const spacing = Utils.isMobile() ? 36 : '2vw';
    const [transcriptVisibility, setTranscriptVisibility] = useState({});

    const onTranscriptVisibilityClick = index => {
        setTranscriptVisibility(props => {
            return update(props, {
                [index]: {
                    $set: true
                }
            });
        });
    }

    const getAssets = () => {
        return assets.map((asset, index) => {
            switch(asset.type) {
                case 'image':
                return (
                    <LayerItem 
                    key={index}
                    lastItem={index === assets.length - 1}
                    spacing={spacing}
                    title={asset.title}>
                        {asset.values && asset.values.map((value, i) => (
                            <ResourceText 
                            key={i}
                            lastItem={false}
                            {...value} />
                        ))}
                        <div style={{
                            backgroundColor: Appearance.colors.divider(),
                            borderRadius: 10,
                            overflow: 'hidden',
                            paddingBottom: Utils.isMobile() === true ? 12 : '7.5%',
                            paddingLeft: Utils.isMobile() === true ? 12 : (window.innerWidth > 1500 ? '25%' : '15%'),
                            paddingRight: Utils.isMobile() === true ? 12 : (window.innerWidth > 1500 ? '25%' : '15%'),
                            paddingTop: Utils.isMobile() === true ? 12 : '7.5%',
                            width: '100%'
                        }}>
                            <img 
                            src={asset.url} 
                            style={{
                                height: 'auto',
                                objectFit: 'contain',
                                width: '100%'
                            }} />
                        </div>
                    </LayerItem>
                )
    
                case 'video':
                return (
                    <LayerItem 
                    key={index}
                    lastItem={index === assets.length - 1}
                    spacing={spacing}
                    title={asset.title}>
                        {asset.values && asset.values.map((value, i) => (
                            <ResourceText 
                            key={i}
                            lastItem={false}
                            {...value} />
                        ))}
                        <div style={{
                            position: 'relative',
                            width: '100%'
                        }}>
                            <video 
                            autoPlay={false}
                            controls={true}
                            preload={'none'}
                            poster={asset.poster_url}
                            style={{
                                borderRadius: 10,
                                height: 'auto',
                                objectFit: 'contain',
                                width: '100%' 
                            }}>
                                <source 
                                src={asset.url} 
                                type={'video/mp4'} />
                            </video>
                            {getVideoTranscript(index)}
                        </div>
                    </LayerItem>
                )
    
                default:
                return null;
            }
        });
    }

    const getContent = () => {
        
        // prepare text values for panel
        let entries = [{
            text: `We're pleased to announce a new ${platformToText(platform)} training article showcasing one of the many features included with the GRACI ${Utils.ucFirst(plan)} tier subscription. In this tutorial, we'll guide you through using ${getFeatureDescription()}. Whether you're a first-time user or looking to refresh your knowledge, our step-by-step approach will ensure you feel confident navigating this feature. Below the video, you'll find a written transcript that outlines everything shown in the video, making it easy for you to follow along or reference later.`
        },{
            text: `An active GRACI subscription for the ${Utils.ucFirst(plan)} tier is required to access this feature. You can signup for a GRACI subscription on the Pricing page or upgrade your current subscription by logging into AFT. When upgrading a subscription on desktop and tablets, subscriptions can be found on the Payments page of the AFT website. When upgrading a subscription using the iOS or Android app, subscriptions can be found on the Settings page. When viewing your subscription details, the "Upgrade Subscription" button can be found by clicking the Options button.`
        }];

        // add custom text values from the content entry if applicable
        if(values && values.length > 0) {
            entries = entries.concat(values);
        }

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: Utils.isMobile() ? 24 : '2vw',
                width: '100%'
            }}>
                {entries.map((entry, index, entries) => {
                    return (
                        <ResourceText 
                        key={index}
                        lastItem={index === entries.length - 1}
                        {...entry} />
                    )
                })}
            </div>
        )
    }

    const getFeatureDescription = () => {

        // remove platform prefix from title and convert to lowercase
        let key = platform.replace('_', ' ');
        let text = title.toLowerCase().replace(key, '');

        // prefix text with 'the' if the title is not plural
        if(text.slice(-1) !== 's') {
            text = `the ${text}`; 
        }
        return text;
    }

    const getVideoTranscript = index => {

        let asset = assets[index];
        if(!asset.transcript) {
            return null;
        }
        if(transcriptVisibility[index] === true) {
            return asset.transcript && (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    display: 'flex',
                    flexDirection: 'column',
                    padding: Utils.isMobile() === true ? 24 : '2vw',
                    width: '100%'
                }}>
                    {asset.transcript.map((entry, index, entries) => {
                        if(!entry.link) {
                            return (
                                <span 
                                key={index}
                                style={{
                                    ...Appearance.textStyles.title(),
                                    lineHeight: 1.2,
                                    marginBottom: index !== entries.length - 1 ? Utils.isMobile() === true ? 24 : '1vw' : 0,
                                    whiteSpace: 'normal'
                                }}>{`${index + 1}. ${entry.text}`}</span>
                            )
                        }

                        let components = entry.text.split(entry.link.label);
                        return (
                            <div 
                            key={index}
                            style={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: index !== entries.length - 1 ? Utils.isMobile() === true ? 24 : '1vw' : 0
                            }}>
                                <span style={{
                                    ...Appearance.textStyles.title(),
                                    lineHeight: 1.2,
                                    whiteSpace: 'normal'
                                }}>
                                    {`${index + 1}. ${components[0]}`}
                                    <span 
                                    className={'text-button'}
                                    key={index}
                                    onClick={window.open.bind(this, entry.link.url)}
                                    style={{
                                        color: Appearance.colors.primary(),
                                        textDecoration: 'underline'
                                    }}>{entry.link.label}</span>
                                    {`${components[1]}`}
                                </span>
                            </div>
                            
                        )
                    })}
                </div>
            )
        }
        return (
            <div 
            className={'row m-0'}
            style={{
                ...Appearance.styles.unstyledPanel(),
                padding: Utils.isMobile() === true ? 12 : '1vw',
                width: '100%'
            }}>
                <div className={'col-12 col-md-8 col-lg-10 p-1 p-md-0'}>
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%'
                    }}>
                        <img
                        className={'d-none d-md-flex'}
                        src={window.theme === 'dark' ? 'images/general-icon-white.png' : 'images/general-icon-grey.png'}
                        style={{
                            height: 45,
                            marginRight: 12,
                            objectFit: 'contain',
                            width: 45
                        }} />
                        <div 
                        className={'pr-0 pr-md-2'}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'left',
                            width: '100%'
                        }}>
                            <span style={{
                                ...Appearance.textStyles.layerItemTitle(),
                                lineHeight: 1.2,
                                marginBottom: 4,
                            }}>{'Video Transcript Available'}</span>
                            <span style={{
                                ...Appearance.textStyles.bodyText(),
                                whiteSpace: 'normal'
                            }}>{'To access the full transcription of the video and read along with the video demonstration, please click the "Transcribe" button, and the text will be displayed for your convenience.'}</span>
                        </div>
                    </div>
                </div>
                <div className={'col-12 col-md-4 col-lg-2 px-0 pt-3 pb-0 p-md-0'}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        justifyContent: 'center',
                        width: '100%'
                    }}>
                        <Button 
                        color={'primary'}
                        label={'Transcribe'}
                        onClick={onTranscriptVisibilityClick.bind(this, index)}
                        type={'large'} />
                    </div>
                </div>
            </div>
        )
    }

    const platformToText = () => {
        switch(platform) {
            case 'aft':
            return 'Applied Fire Technologies';

            case 'dreamcatcher':
            return 'DreamCatcher';

            case 'flipchart':
            return 'FlipChart';

            case 'global_data':
            return 'Global Data';

            case 'omnishield':
            return 'OmniShield';
        }
    }

    return (
        <ResourceContainer
        date={date}
        id={id}
        title={title}
        utils={utils}
        style={style}>
            {getContent()}
            {getAssets()}
        </ResourceContainer>
    )
}

const NewSupportContentCommunication = ({ id, title }, { index, options, utils }) => {

    const layerID = 'new_support_content_communication';
    const [edits, setEdits] = useState({});
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onUpdateTarget = props => {
        setEdits(edits => ({ ...edits, ...props }));
    }

    const onSubmitClick = async () => {
        try {

            // verify that required fields were provided
            await validateRequiredFields(getFields);

            // verify that conflicting values were not provided
            if(edits.level && edits.users && edits.users.length > 0) {
                let matches = edits.users.filter(user => user.level === edits.level ? false : true);
                if(matches.length > 0) {
                    throw new Error(`It looks like you have selected ${matches.length} ${matches.length === 1 ? 'user' : 'users'} who are not safety advisors but have requested that we only send these push notifications to safety advisors. Please remove the Safety Advisor filter or remove the conflicting users.`);
                }
            }

            // set loading flag and submit request to server
            setLoading('submit');
            await Request.post(utils, '/utils/', {
                ...edits,
                id: id,
                users: edits.users && edits.users.map(user => user.user_id),
                type: 'new_support_content_communication'
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done',
                message: `Your push notifications has been submitted and will be delivered shortly`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue submitting your push notifications. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'submit',
            loading: loading === 'submit',
            onClick: onSubmitClick,
            text: 'Submit'
        }];
    }

    const getFields = () => {
        return [{
            key: 'content',
            title: 'Content',
            items: [{
                component: 'textfield',
                key: 'title',
                onChange: text => onUpdateTarget({ title: text }),
                title: 'Title',
                value: edits.title
            },{
                component: 'textview',
                key: 'message',
                onChange: text => onUpdateTarget({ message: text }),
                title: 'Message',
                value: edits.message
            }]
        },{
            key: 'restrictions',
            title: 'Restrictions',
            items: [{
                component: 'list',
                items: getLevels(),
                key: 'levels',
                onChange: item => onUpdateTarget({ level: item && item.id }),
                required: false,
                title: 'Account Type',
                value: edits.level && getLevels().find(entry => entry.id === edits.level).title
            },{
                component: 'multiple_user_lookup',
                key: 'users',
                onChange: users => onUpdateTarget({ users }),
                props: {
                    levels: edits.level ? [edits.level] : null,
                    restrictToDealership: true,
                    restrictToGenealogy: true
                },
                required: false,
                title: 'Users',
                value: edits.users
            }]
        }];
    }

    const getLevels = () => {
        return [{
            id: User.levels.get().safety_advisor,
            title: 'Safety Advisor'
        }];
    }

    const setupEditing = () => {
        setEdits({
            message: 'I highly recommend you check out this tutorial. It offers step-by-step guidance that can help you enhance your skills and deepen your understanding.',
            title: title
        });
    }

    useEffect(() => {
        setupEditing();
    }, []);

    return (
        <Layer 
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Share via Push Notification: ${title}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()}
            utils={utils} />
        </Layer>
    )
}

const ResourceContainer = ({ children, date, id, style, title, utils }) => {

    const padding = Utils.isMobile() ? 24 : '2vw';

    const onNewCommunication = () => {
        utils.layer.open({
            Component: NewSupportContentCommunication.bind(this, { id, title }),
            id: 'new_support_content_communication'
        });
    }

    const onShareClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'copy',
                title: 'Copy Link',
                style: 'default'
            },{
                key: 'push_notification',
                title: 'Push Notification',
                style: 'default'
            }],
            position: 'bottom',
            target: evt.target
        }, key => {
            if(key === 'copy') {
                Utils.copyText(`${API.server}/dealers/resources/content/${id}`);
                return;
            }
            if(key === 'push_notification') {
                onNewCommunication();
                return;
            }
        });
    }

    const getAccessories = () => {
        return (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                position: 'absolute',
                right: 24,
                top: 24
            }}>
                <img 
                className={'text-button'}
                onClick={onShareClick}
                src={'images/share-icon-grey.png'}
                style={{
                    height: 25,
                    marginRight: 12,
                    objectFit: 'contain',
                    width: 18
                }} />
                <img 
                className={'text-button'}
                src={'images/bookmark-grey.png'}
                style={{
                    height: 23,
                    objectFit: 'contain',
                    width: 18
                }} />
            </div>
        )
    }

    return (
        <AnimatedElement 
        animateIn={{
            opacity: 1,
            top: 0
        }}
        animateOut={{
            opacity: 0,
            top: -50
        }}
        visible={true}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                width: '100%',
                ...style
            }}>
                {getAccessories()}
                <div style={{
                    borderBottom: `1px solid ${Appearance.colors.divider()}`,
                    display: 'flex',
                    flexDirection: 'column',
                    paddingBottom: padding,
                    paddingLeft: padding,
                    paddingRight: 85,
                    paddingTop: padding,
                    width: '100%'
                }}>
                    <span style={{
                        ...Appearance.textStyles.heavyHeader(),
                        fontSize: Utils.isMobile() === true ? 20 : 24,
                        lineHeight: 1.2,
                        marginBottom: 8,
                        whiteSpace: 'normal'
                    }}>{title}</span>
                    <span style={{
                        ...Appearance.textStyles.title(),
                        color: Appearance.colors.subText(),
                        lineHeight: 1.2,
                        whiteSpace: 'normal'
                    }}>{Utils.formatDate(date)}</span>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: padding,
                    width: '100%'
                }}>
                    {children}
                </div>
            </div>
        </AnimatedElement>
    )
}

export const Resources = ({ utils }) => {

    const limit = 10;
    const offset = useRef(0);
    const searchTextRef = useRef(null);

    const [categories, setCategories] = useState([]);
    const [content, setContent] = useState([]);
    const [contentTypes, setContentTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [subscriptions, setSubscriptions] = useState([]);

    const onManuallyStartSearch = () => {
        offset.current = 0;
        setLoading('search');
        fetchResources();
    }

    const onSearchFieldKeyDown = value => {
        if(value === 'enter') {
            onManuallyStartSearch();
        }
    }

    const onSearchTextChange = text => {

        // reset offset for paging
        offset.current = 0;

        // update search ref and search text state
        searchTextRef.current = text;
        setSearchText(text);

        // fetch resources if all search text has been removed
        if(!text || text.length === 0) {
            setLoading(true);
            fetchResources();
        }
    }

    const onViewMoreResultsClick = () => {
        offset.current = offset.current + limit;
        setLoading('paging');
        fetchResources();
    }

    const getCategoryItems = () => {
        return [{
            id: 'aft',
            title: 'Applied Fire Technologies'
        },{
            id: 'flipchart',
            title: 'FlipChart'
        },{
            id: 'global_data',
            title: 'Global Data'
        },{
            id: 'omnishield',
            title: 'OmniShield'
        }];
    }

    const getContentTypeItems = () => {
        return [{
            id: 'article',
            title: 'General Content'
        },{
            id: 'tutorial',
            title: 'Tutorials'
        },{
            id: 'kb_article',
            title: 'Knowledge Base Articles'
        }];
    }

    const getPagingComponents = () => {
        let { current_page = 0, number_of_pages = 0 } = paging || {};
        return current_page < number_of_pages && (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                marginTop: '2vw',
                width: '100%'
            }}>
                <Button 
                color={'primary'}
                label={'View More'}
                laoding={loading === 'paging'}
                onClick={onViewMoreResultsClick} 
                type={'large'} />
            </div>
        )
    }

    const getResourcesContent = () => {
        if(loading === true || loading === 'search') {
            return (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginTop: '2vw',
                    maxWidth: 1000,
                    padding: Appearance.styles.panelPadding(),
                    width: '100%'
                }}>
                    <LottieView
                    loop={true}
                    autoPlay={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 40,
                        width: 40
                    }}/>
                </div>
            )
        }
        if(content.length === 0) {
            return (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginTop: '2vw',
                    maxWidth: 1000,
                    padding: Appearance.styles.panelPadding()
                }}>
                    <img
                    src={window.theme === 'dark' ? 'images/no-resources-found-white.png' : 'images/no-resources-found-black.png'}
                    style={{
                        height: 65,
                        marginBottom: 8,
                        objectFit: 'contain',
                        width: 65
                    }} />
                    <span style={{
                        ...Appearance.textStyles.layerItemTitle(),
                        marginBottom: 4,
                    }}>{'No Resources Found'}</span>
                    <span style={{
                        ...Appearance.textStyles.bodyText(),
                        textAlign: 'center'
                    }}>{`It looks like no search results were found for your query. This can happen if the terms you used are too specific or if there aren't any articles or tutorials that match your request. We recommend trying different keywords or phrases to broaden your search.`}</span>
                </div>
            )
        }
        return content.map((entry, index) => {
            if(entry.id === 'flipchart_device_models') {
                return (
                    <FlipChartDeviceModels
                    {...entry}
                    key={index} 
                    style={{
                        marginTop: Appearance.styles.panelSpacing()
                    }}
                    utils={utils} />
                )
            }
            return (
                <GraciSupportContent
                {...entry}
                key={index} 
                style={{
                    marginTop: Appearance.styles.panelSpacing()
                }}
                utils={utils} />
            )
        });
    }

    const getSearchClearButton = () => {
        if(Utils.isMobile() === true) {
            return (
                <img 
                className={'text-button'}
                onClick={onSearchTextChange.bind(this, null)}
                src={'images/search-clear-button-grey.png'}
                style={{
                    borderRadius: 15,
                    height: 30,
                    marginRight: 4,
                    minWidth: 30,
                    width: 30
                }} />
            )
        }
        return (
            <div 
            className={'text-button'}
            onClick={onSearchTextChange.bind(this, null)}
            style={{
                alignItems: 'center',
                backgroundColor: Appearance.colors.grey(),
                borderRadius: 15,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginRight: 8,
                padding: '4px 16px 4px 16px'
            }}>
                <span style={{
                    color: 'white',
                    fontSize: 14,
                    fontWeight: 700,
                    height: 21
                }}>{'Clear'}</span>
            </div>
        )
    }

    const getSearchComponents = () => {
        return (
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                width: '100%'
            }}>
                <div 
                className={'text-left text-md-center align-items-left align-items-md-center'}
                style={{
                    borderBottom: `1px solid ${Appearance.colors.divider()}`,
                    display: 'flex',
                    flexDirection: 'column',
                    padding: Appearance.styles.panelPadding(),
                    width: '100%'
                }}>
                    <span style={{
                        ...Appearance.textStyles.pageTitle(),
                        marginBottom: 12
                    }}>{'DEALER RESOURCES'}</span>
                    <span 
                    className={'mb-4 mb-md-5'}
                    style={{
                        ...Appearance.textStyles.bodyText(),
                        width: '100%'
                    }}>{`The search bar is designed to help you quickly locate support articles and tutorials. By entering keywords or phrases related to your topic, you'll gain access to a library of resources specifically tailored to your needs. The drop-down menus are an excellent way to streamline your search by offering a variety of filters to refine your results.`}</span>
                    <TextField
                    appendContent={getSearchButtons()}
                    containerStyle={{
                        borderRadius: 35,
                        paddingBottom: 28,
                        paddingLeft: 28,
                        paddingRight: 16,
                        paddingTop: 28
                    }}
                    onChange={onSearchTextChange}
                    onKeyDown={onSearchFieldKeyDown}
                    placeholder={'What can we help you find?'}
                    useDelay={true}
                    value={searchText} />
                </div>
                <div className={'row m-0 p-3'}>
                    <div className={'col-12 col-lg-4 p-2'}>
                        <MultipleListField
                        items={getCategoryItems()} 
                        onChange={items => setCategories(items && items.map(item => item.id))}
                        placeholder={'Category'} />
                    </div>
                    <div className={'col-12 col-lg-4 p-2'}>
                        <MultipleListField
                        items={getSubscriptionItems()} 
                        onChange={items => setSubscriptions(items && items.map(item => item.id))}
                        placeholder={'Subscription'} />
                    </div>
                    <div className={'col-12 col-lg-4 p-2'}>
                        <MultipleListField
                        items={getContentTypeItems()} 
                        onChange={items => setContentTypes(items && items.map(item => item.id))}
                        placeholder={'Content Type'} />
                    </div>
                </div>
            </div>
        )
    }

    const getSearchSubmitButton = () => {
        if(Utils.isMobile() === true) {
            return (
                <img 
                className={'text-button'}
                onClick={onManuallyStartSearch}
                src={'images/search-submit-button-primary.png'}
                style={{
                    borderRadius: 15,
                    height: 30,
                    minWidth: 30,
                    width: 30
                }} />
            )
        }
        return (
            <div 
            className={'text-button'}
            onClick={onManuallyStartSearch}
            style={{
                alignItems: 'center',
                backgroundColor: Appearance.colors.primary(),
                borderRadius: 15,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: '4px 16px 4px 16px'
            }}>
                {loading === 'search' && (
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={require('files/lottie/dots-white.json')}
                    style={{
                        height: 21,
                        width: 25
                    }}/>
                )}
                {loading !== 'search' && (
                    <span style={{
                        color: 'white',
                        fontSize: 14,
                        fontWeight: 700,
                        height: 21
                    }}>{'Search'}</span>
                )}
            </div>
        )
    }

    const getSearchButtons = () => {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row'
            }}>
                <AnimatedElement 
                animateIn={{
                    opacity: 1,
                    transform: 'scale(1)'
                }}
                animateOut={{
                    opacity: 0,
                    transform: 'scale(0.5)'
                }}
                visible={searchText && searchText.length > 0 ? true : false}>
                    {getSearchClearButton()}
                </AnimatedElement>
                <AnimatedElement 
                animateIn={{
                    opacity: 1,
                    transform: 'scale(1)'
                }}
                animateOut={{
                    opacity: 0,
                    transform: 'scale(0.5)'
                }}
                visible={searchText && searchText.length > 0 ? true : false}>
                    {getSearchSubmitButton()}
                </AnimatedElement>
            </div>
        )
    }

    const getSubscriptionItems = () => {
        return [{
            id: 'none',
            title: 'No Subscription Required'
        },{
            id: 'bronze',
            title: 'Bronze'
        },{
            id: 'silver',
            title: 'Silver'
        },{
            id: 'gold',
            title: 'Gold'
        }];
    }

    const fetchResources = async () => {
        try {

            // determine if routing data is present and set search text if applicable
            let { content_id, route } = utils.routing.get() || {};
            if(route === 'resources') {
                searchTextRef.current = content_id;
                setSearchText(searchTextRef.current);
                utils.routing.set(null);
            }

            // fetch graci support content from server
            let { content, paging } = await Request.get(utils, '/utils/', {
                categories: categories,
                content_types: contentTypes,
                limit: limit,
                offset: offset.current,
                search_text: searchTextRef.current,
                subscriptions: subscriptions,
                type: 'dealer_resources_content'
            });

            // format entry results
            let entries = content.map(entry => ({
                ...entry,
                date: moment.utc(entry.date).local()
            }));

            // append results to local state if paging has advanced past the first page
            setContent(results => offset.current > 0 ? results.concat(entries) : entries);

            // end loading and update state for paging props
            setLoading(false);
            setPaging(paging);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the dealer resources list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        setLoading(true);
        fetchResources();
    }, [categories, contentTypes, subscriptions]);

    return (
        <div style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            padding: Utils.isMobile() === true ? 12 : '1vw',
            width: '100%'
        }}>
            {getSearchComponents()}
            {getResourcesContent()}
            {getPagingComponents()}
        </div>
    )
}

const ResourceText = ({ lastItem = false, style, text }) => {

    const margin = Utils.isMobile() ? 24 : '1vw';

    return (
        <span style={{
            ...Appearance.textStyles.bodyText(),
            display: 'block',
            marginBottom: lastItem === false && margin,
            whiteSpace: 'normal',
            ...style
        }}>{text}</span>
    )
}