import React, { useEffect, useRef, useState } from 'react';

import Appearance from 'styles/Appearance.js';
import { FloatingMenuIndex } from 'structure/Layer.js';
import PageControl from 'views/PageControl.js';
import Request from 'files/Request.js';
import TextField from 'views/TextField.js';
import Views from 'views/Main.js';

const UserLookupField = ({ containerStyle, hideAvatar, icon, inline, levels, onChange, placeholder, preserveResult = true, restrictToDealership = false, restrictToGenealogy = false, user, utils }) => {

    const limit = 5;
    const offset = useRef(0);

    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);
    const [results, setResults] = useState([]);
    const [selected, setSelected] = useState(false);
    const [text, setText] = useState('');

    const onClick = async user => {

        // clear results list
        setResults([]);

        // reset text field if preserve result is disabled
        if(preserveResult === false) {
            setText('');
        }

        // set text value for text field and selected user if preserve result is enabled
        if(preserveResult === true) {
            setSelected(user);
            setText(user.full_name);
        }

        // notify subscribers of data change
        if(typeof(onChange) === 'function') {
            onChange(user);
        }
    }

    const onTextChange = text => {
        setText(text);
        setLoading(true);
    }

    const fetchResults = async () => {
        try {

            // update state with new text value and remove previously selected result
            setSelected(false);
            setText(text);

            // prevent search from firing if less than three characters were provided
            if(text.length < 3) {
                setResults([]);
                if(typeof(onChange) === 'function') {
                    onChange(null);
                }
                return;
            }

            // set loading flag and submit request to server
            let { users } = await Request.get(utils, '/users/', {
                include_dealerships: true,
                limit: 10,
                levels: levels,
                offset: offset.current,
                restrict_to_dealership: restrictToDealership,
                restrict_to_genealogy: restrictToDealership === true ? true : restrictToGenealogy,
                search_text: text,
                type: 'user_lookup'
            });

            console.log(users);
            // end loading and set results
            setLoading(false);
            setPaging(paging);
            setResults(users);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue searching for user accounts. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getStyles = () => {
        if(inline === false) {
            return {
                backgroundColor: window.theme === 'dark' ? 'rgba(100,100,100,1)' : 'white',
                border: `1px solid rgba(175,175,175,0.25)`,
                borderRadius: 8,
                boxShadow: window.theme === 'dark' ? '4px 4px 7px rgba(174,174,174,0.25)' : '4px 4px 7px rgba(0,0,0,0.15)',
                left: 0,
                maxHeight: 250,
                overflowY: 'scroll',
                padding: 0,
                position: 'absolute',
                right: 0,
                textAlign: 'left',
                top: 43,
                zIndex: FloatingMenuIndex
            }
        }
        return {
            backgroundColor: window.theme === 'dark' ? 'rgba(100,100,100,1)' : 'white',
            border: `1px solid ${Appearance.colors.divider()}`,
            borderRadius: 12,
            marginTop: 8,
            overflow: 'hidden',
            padding: 0,
            textAlign: 'left'
        }
    }

    useEffect(() => {
        fetchResults();
    }, [text]);

    useEffect(() => {
        setSelected(user);
        setText(user && user.full_name || '');
    }, [user]);

    return (
        <div style={{
            position: 'relative',
            width: '100%',
            ...containerStyle
        }}>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start'
            }}>
                {selected && hideAvatar !== true && (
                    <img
                    src={selected.avatar || 'images/user-placeholder.png'}
                    style={{
                        borderRadius: 17.5,
                        height: 35,
                        marginRight: 8,
                        minHeight: 35,
                        minWidth: 35,
                        objectFit: 'cover',
                        overflow: 'hidden',
                        width: 35,
                    }} />
                )}
                <TextField
                autoComplete={false}
                autoCorrect={false}
                autoCapitalize={'sentences'}
                icon={icon || 'search'}
                loading={loading}
                onChange={onTextChange}
                placeholder={placeholder || 'Search by name or user id...'}
                useDelay={true}
                value={text}
                style={{
                    flexGrow: 1,
                    ...Appearance.textStyles.title()
                }}/>
            </div>
            {results.length > 0 && (
                <div style={getStyles()}>
                    {results.map((user, index) => {
                        return (
                            Views.entry({
                                badge: {
                                    color: Appearance.colors.grey(),
                                    text: user.dealership ? user.dealership.name : 'Dealership not available'
                                },
                                bottomBorder: index !== results.length - 1,
                                icon: {
                                    path: user.avatar
                                },
                                key: index,
                                lastItem: index === results.length - 1,
                                onClick: onClick.bind(this, user),
                                style: {
                                    padding: '6px 12px 6px 12px'
                                },
                                subTitle: user.phone_number,
                                title: user.full_name
                            })
                        )
                    })}
                    {paging && (
                        <PageControl
                        data={paging}
                        limit={limit}
                        loading={loading}
                        offset={offset.current}
                        onClick={next => {
                            offset.current = next;
                            setLoading('paging');
                            fetchResults();
                        }} />
                    )}
                </div>
            )}
        </div>
    )
}
export default UserLookupField;
