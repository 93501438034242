import React from 'react';
import Appearance from 'styles/Appearance.js';
import ProgressBar from 'views/ProgressBar.js';

const PageControl = ({ data, limit, loading, offset, onClick, style }) => {

    const onPageClick = direction => {

        let nextOffset = 0;
        switch(direction) {
             case 'next':
             nextOffset = offset + limit;
             break;

             case 'back':
             nextOffset = offset - limit;
             break;

             default:
             if(!isNaN(direction)) {
                 nextOffset = (direction - 1) * limit;
             }
        }
        if(typeof(onClick) === 'function') {
            onClick(nextOffset < 0 ? 0 : nextOffset);
        }
    }

    const getContent = () => {

        if(!data) {
            return null;
        }

        let { current_page, number_of_pages } = data;
        if(number_of_pages <= 1) {
            return null;
        }
        return (
            <div style={{
                borderTop: `1px solid ${Appearance.colors.divider()}`,
                padding: 10,
                position: 'relative',
                ...style
            }}>
                {loading === true && (
                    <div style={{
                        borderRadius: 2,
                        left: 0,
                        overflow: 'hidden',
                        position: 'absolute',
                        height: 2,
                        right: 0,
                        top: 0,
                        width: '100%'
                    }}>
                        <ProgressBar/>
                    </div>
                )}
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    margin: 'auto',
                }}>
                    <img
                    title={'Back to the Beginning'}
                    src={'images/back-double-arrow-light-grey-small.png'}
                    className={current_page === 1 ? '' : 'image-button'}
                    onClick={onPageClick.bind(this, 0)}
                    style={{
                        marginRight: 4,
                        opacity: current_page === 1 ? 0:1,
                        width: 12,
                        height: 12,
                        objectFit: 'contain'
                    }}/>
                    <img
                    title={'Previous Page'}
                    src={'images/back-arrow-light-grey-small.png'}
                    className={current_page === 1 ? '' : 'image-button'}
                    onClick={onPageClick.bind(this, 'back')}
                    style={{
                        marginRight: 8,
                        opacity: current_page === 1 ? 0:1,
                        width: 12,
                        height: 12,
                        objectFit: 'contain'
                    }}/>
                    {[...Array(number_of_pages < 5 ? number_of_pages : 5)].map((e, index) => {

                        let pageNumber = current_page > 3 ? ((index + 1) + current_page) - 3 : (index + 1);

                        let highlighted = current_page > 3 ? (index === 2) : ((index + 1) === current_page);

                        if(pageNumber > number_of_pages) {
                            return null;
                        }

                        return (
                            <div
                            key={pageNumber}
                            className={'image-button'}
                            onClick={onPageClick.bind(this, pageNumber)}
                            style={{
                                padding: '0px 4px 0px 4px'
                            }}>
                                <div style={{
                                    display: 'flex',
                                    width: '100%',
                                    minWidth: 20,
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    marginTop: 3
                                }}>
                                    <span
                                    key={pageNumber}
                                    style={{
                                        display: 'block',
                                        width: '100%',
                                        color: Appearance.colors.subText(),
                                        fontWeight: '600',
                                        fontSize: '12px',
                                        padding: pageNumber > 9 ? '0px 7px 0px 7px' : '0px'
                                    }}>{pageNumber}</span>
                                    <div style={{
                                        width: '100%',
                                        height: 3,
                                        borderRadius: 1.5,
                                        overflow: 'hidden',
                                        backgroundColor: highlighted ? Appearance.colors.primary() : Appearance.colors.transparent
                                    }} />
                                </div>
                            </div>
                        )
                    })}
                    <img
                    title={'Next Page'}
                    src={'images/next-arrow-light-grey-small.png'}
                    className={current_page !== number_of_pages ? 'image-button' : ''}
                    onClick={current_page !== number_of_pages ? onPageClick.bind(this, 'next') : null}
                    style={{
                        marginLeft: 8,
                        opacity: current_page !== number_of_pages ? 1:0,
                        width: 12,
                        height: 12,
                        objectFit: 'contain'
                    }}/>
                    <img
                    title={'Very Last Page'}
                    src={'images/next-double-arrow-light-grey-small.png'}
                    className={current_page !== number_of_pages ? 'image-button' : ''}
                    onClick={current_page !== number_of_pages ? onPageClick.bind(this, number_of_pages) : null}
                    style={{
                        marginLeft: 4,
                        opacity: current_page !== number_of_pages ? 1:0,
                        width: 12,
                        height: 12,
                        objectFit: 'contain'
                    }}/>
                </div>
            </div>
        )
    }

    return getContent();
}

export default PageControl;
