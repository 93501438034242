import moment from 'moment';
import Request from 'files/Request.js';

class UserClass {

    active = true;
    address = null;
    avatar = null;
    created = null;
    dealership = null;
    email_address = null;
    first_name = null;
    full_name = null;
    genealogy = null;
    last_name = null;
    level = null;
    location = null;
    one_to_one = null;
    phone_number = null;
    token = null;
    recruitment = null;
    refresh_token = null;
    spouse = null;
    timezone = null;
    user_id = null;
    username = null;
    
    constructor() {
        return this;
    }

	create = (props = {}) => {
        this.active = props.active;
        this.address = props.address;
        this.avatar = props.avatar;
        this.created = props.created && moment(props.created);
        this.dealership = props.dealership;
        this.email_address = props.email_address;
        this.first_name = props.first_name;
        this.full_name = props.full_name;
        this.genealogy = props.genealogy;
        this.last_name = props.last_name;
        this.level = props.level;
        this.location = props.location && {
            latitude: props.location.lat,
            longitude: props.location.long
        };
        this.one_to_one = props.one_to_one;
        this.phone_number = props.phone_number;
        this.recruitment = props.recruitment;
        this.refresh_token = props.refresh_token;
        this.spouse = props.spouse;
        this.timezone = props.timezone;
        this.token = props.token;
        this.user_id = props.user_id;
        this.username = props.username;
        return this;
	}
}

export const fetchUser = async (utils, userID) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { user } = await Request.get(utils, '/users/', {
                type: 'details',
                user_id: userID
            });
            resolve(new UserClass().create(user));
        } catch(e){
            reject(e);
        }
    });
}

const levels = {
    system_admin: 1,
    admin: 2,
    exigent_admin: 2000,
    region_director: 3000,
    division_director: 3250,
    area_director: 3500,
    vested_director: 3750,
    dealer: 4000,
    booking_coordinator: 4250,
    marketing_director: 4251,
    safety_advisor: 5000,
    safety_associate: 7000
}

const levelToText = code => {
    switch(code) {
        case levels.system_admin:
        return 'System Administrator';

        case levels.admin:
        return 'Administrator';

        case levels.exigent_admin:
        return 'Exigent Administrator';

        case levels.region_director:
        return 'Region Director';

        case levels.division_director:
        return 'Division Director';

        case levels.area_director:
        return 'Area Director';

        case levels.vested_director:
        return 'Vested Director';

        case levels.dealer:
        return 'Dealer';

        case levels.booking_coordinator:
        return 'Booking Coordinator';

        case levels.marketing_director:
        return 'Marketing Director';

        case levels.safety_advisor:
        return 'Safety Advisor';

        case levels.safety_associate:
        return 'Safety Associate';

        default:
        return 'Unknown account type';
    }
}

export default {
    new: () => new UserClass(),
    get: fetchUser,
    create: props => new UserClass().create(props),
    levels: {
        get: () => levels,
        toText: levelToText
    }
};
