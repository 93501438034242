import React, { useRef, useState, useEffect } from 'react';
import Appearance from 'styles/Appearance.js';
import { VelocityComponent } from 'velocity-react';

const BoolToggle = ({ color, disabled, enabled, isEnabled, labelStyle, onChange, requireInteraction, style }) => {

    const ref = useRef(null);
    const [left, setLeft] = useState(0);
    const [opacity, setOpacity] = useState(0);
    const [status, setStatus] = useState(isEnabled);

    const hasValidStatus = () => {
        if(requireInteraction === true) {
            return typeof(status) === 'boolean';
        }
        return true;
    }

    const onRevokeInteraction = () => {
        setStatus(null);
        if(typeof(onChange) === 'function') {
            onChange(null);
        }
    }

    const onToggleClick = val => {
        setLeft(val ? (ref.current.clientWidth / 2) : 0);
        setStatus(val);
        if(typeof(onChange) === 'function') {
            onChange(val);
        }
    }

    useEffect(() => {
        setStatus(isEnabled);
    }, [isEnabled]);

    useEffect(() => {
        setOpacity(hasValidStatus() ? 1 : 0);
    }, [status]);

    useEffect(() => {
        setLeft(hasValidStatus() && status === true ? (ref.current.clientWidth / 2) : 0);
    }, [ref.current, status]);

    return (
        <div style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            height: 30,
            ...style
        }}>
            <div
            ref={ref}
            style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                height: 30,
                borderRadius: 15,
                border: `1px solid ${Appearance.colors.softBorder()}`,
                boxShadow: Appearance.boxShadow(),
                overflow: 'hidden',
                margin: 'auto'
            }}>
                <VelocityComponent
                easing={[250, 20]}
                duration={500}
                animation={{
                    left: left,
                    opacity: opacity
                }}>
                    <div
                    className={'cursor-pointer'}
                    onClick={onToggleClick.bind(this, false)}
                    style={{
                        position: 'absolute',
                        width: '50%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 15,
                        backgroundColor: color || Appearance.colors.primary()
                    }}>
                        <span style={{
                            fontSize: 12,
                            fontWeight: 500,
                            ...labelStyle,
                            color: 'white'
                        }}>{status ? enabled : disabled}</span>
                    </div>
                </VelocityComponent>
                <div
                className={'cursor-pointer'}
                onClick={onToggleClick.bind(this, false)}
                style={{
                    width: '50%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 12,
                    paddingRight: 12,
                    borderRight: !hasValidStatus() ? `1px solid ${Appearance.colors.softBorder()}` : null
                }}>
                    <span style={{
                        fontSize: 12,
                        fontWeight: 500,
                        ...labelStyle,
                        color: status || !hasValidStatus() ? Appearance.colors.text() : 'white'
                    }}>{disabled}</span>
                </div>
                <div
                className={'cursor-pointer'}
                onClick={onToggleClick.bind(this, true)}
                style={{
                    width: '50%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 12,
                    paddingRight: 12
                }}>
                    <span style={{
                        fontSize: 12,
                        fontWeight: 500,
                        ...labelStyle,
                        color: status && hasValidStatus() ? 'white' : Appearance.colors.text()
                    }}>{enabled}</span>
                </div>
            </div>
            {requireInteraction && status !== null && (
                <img
                className={'text-button'}
                onClick={onRevokeInteraction}
                src={'images/close-button-light-small.png'}
                style={{
                    width: 25,
                    height: 25,
                    marginLeft: 8
                }} />
            )}
        </div>
    )
}
export default BoolToggle;
